import React from 'react'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Login from './components/Login'
import VisNetwork from './components/VisNetwork';

function App() {
  return (
    <Router>
        <Routes>
            <Route exact path="/" element={<VisNetwork />} />
            <Route path="/login" element={<Login />} />
        </Routes>
    </Router>
  )
}
export default App