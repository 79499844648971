import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import axios from'axios'

const Login = () => {
    const navigate = useNavigate();

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [error,setError] = useState('')

    const onSubmit = async () => {
        let URL = "https://auth-server-tkbp.onrender.com/login/sdtech"
        let response = await axios.post(URL, JSON.stringify({
            'u': username, 'p': password
        }),{ headers:{ "Content-Type": "application/json; charset=UTF-8"}})
        console.log(response)
        if(response && response.data && response.data.status === "RES_SUCCESS"){
            sessionStorage.setItem('token', response.data.data.token);
            navigate("/");
        }
        else{
            setError(response.data.status || 'Unknown Error')
        }
    }

    useEffect(() => {
        if(sessionStorage.getItem('token') !== null){
            navigate("/");
        }
      }, []);

    return (
        <div className="App">
            <div className="auth-wrapper">
                <div className="auth-inner">
                    <h3>Sign In</h3>
                    <div className="mb-3">
                    <label>Username</label>
                    <input
                        type="username"
                        className="form-control"
                        placeholder="Enter username"
                        value={username}
                        onChange={(e)=>setUsername(e.target.value)}
                    />
                    </div>
                    <div className="mb-3">
                    <label>Password</label>
                    <input
                        type="password"
                        className="form-control"
                        placeholder="Enter password"
                        value={password}
                        onChange={(e)=>setPassword(e.target.value)}
                    />
                    </div>
                    <div className="mb-3">
                    </div>
                    <div className="d-grid">
                    <button className="btn btn-primary" onClick={onSubmit}>
                        Submit
                    </button>
                    <br />
                    <p className="error-msg text-center">
                     {error}
                    </p>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Login