import React, { useEffect, useRef } from 'react';
import { DataSet } from 'vis-data';
import { Network } from 'vis-network';
import 'vis-network/styles/vis-network.css';

const KnowledgeGraph = ({ data }) => {
    const networkRef = useRef(null);
  
    useEffect(() => {
      if (data && networkRef.current) {
        // Create a DataSet from the data
        const nodes = new DataSet(data.nodes);
        const edges = new DataSet(data.edges);
  
        // Create the network instance
        const container = networkRef.current;
        const options = {
            physics: { 
                "stabilization": false,
                // "nodes": {
                //     "shape": "circularImage",
                //     "shapeProperties": {
                //       "borderRadius": 20,
                //       "useImageSize": true
                //     },
                //     "size": 87
                //   },
                // "edges": {
                //     "smooth": {
                //       "roundness": 0.3
                //     }
                //   },
                //   "physics": {
                //     "barnesHut": {
                //       "theta": 0.45
                //     },
                //     "maxVelocity": 265,
                //     "minVelocity": 1
                //   }
            },
            
        }; // Configure network options if needed
        const network = new Network(container, { nodes, edges }, options);
      }
    }, [data]);
  
    return <div  className='index_body2' ref={networkRef} />;
  };
  
  export default KnowledgeGraph;