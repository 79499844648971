import React, { useState, useEffect } from 'react';
import KnowledgeGraph from './KnowledgeGraph';
import { useNavigate } from "react-router-dom";
import axios from'axios'

const NODES_DATA = [
    { id: 1,  label: 'Node 1', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/d/d8/Leonardo_da_Vinci_LUCAN_self-portrait_PORTRAIT.jpg/282px-Leonardo_da_Vinci_LUCAN_self-portrait_PORTRAIT.jpg" },
    { id: 2,  label: 'Node 2', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/2/27/Leonardo_da_Vinci_-_Adorazione_dei_Magi_-_Google_Art_Project.jpg/400px-Leonardo_da_Vinci_-_Adorazione_dei_Magi_-_Google_Art_Project.jpg" },
    { id: 3,  label: 'Node 3', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/5/56/Leonardo_da_Vinci_-_Virgin_of_the_Rocks_%28Louvre%29.jpg/245px-Leonardo_da_Vinci_-_Virgin_of_the_Rocks_%28Louvre%29.jpg" },
    { id: 4,  label: 'Node 4', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/e/ec/Mona_Lisa%2C_by_Leonardo_da_Vinci%2C_from_C2RMF_retouched.jpg/268px-Mona_Lisa%2C_by_Leonardo_da_Vinci%2C_from_C2RMF_retouched.jpg" },
    { id: 5,  label: 'Node 5', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/4/4b/%C3%9Altima_Cena_-_Da_Vinci_5.jpg/400px-%C3%9Altima_Cena_-_Da_Vinci_5.jpg" },
    { id: 6,  label: 'Node 6', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/f/f9/Lady_with_an_Ermine_-_Leonardo_da_Vinci_-_Google_Art_Project.jpg/297px-Lady_with_an_Ermine_-_Leonardo_da_Vinci_-_Google_Art_Project.jpg" },
    { id: 7,  label: 'Node 7', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/2/22/Da_Vinci_Vitruve_Luc_Viatour.jpg/294px-Da_Vinci_Vitruve_Luc_Viatour.jpg" },
    { id: 8,  label: 'Node 8', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/3/3b/Annunciation_%28Leonardo%29.jpg/400px-Annunciation_%28Leonardo%29.jpg" },
    { id: 9,  label: 'Node 9', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/9/98/Leonardo_da_Vinci_-_Saint_Jerome.jpg/283px-Leonardo_da_Vinci_-_Saint_Jerome.jpg" },
    { id: 10, label: 'Node 10', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Leonardo_da_Vinci_-_Virgin_and_Child_with_St_Anne_C2RMF_retouched.jpg/298px-Leonardo_da_Vinci_-_Virgin_and_Child_with_St_Anne_C2RMF_retouched.jpg" },
    { id: 11, label: 'Node 11', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/b/b8/Leonardo_da_Vinci_-_Saint_John_the_Baptist_C2RMF_retouched.jpg/309px-Leonardo_da_Vinci_-_Saint_John_the_Baptist_C2RMF_retouched.jpg" },
    { id: 12, label: 'Node 12', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/5/54/Szkic_%C5%9Bmig%C5%82owca.jpg/400px-Szkic_%C5%9Bmig%C5%82owca.jpg" },
    { id: 13, label: 'Node 13', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/3/38/Da_Vinci_Isabella_d%27Este.jpg/294px-Da_Vinci_Isabella_d%27Este.jpg" },
    { id: 14, label: 'Node 14', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/b/b2/Lascapigliata.jpg/308px-Lascapigliata.jpg" },
    { id: 15, label: 'Node 15', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Leonardo_da_Vinci%2C_Salvator_Mundi%2C_c.1500%2C_oil_on_walnut%2C_45.4_%C3%97_65.6_cm.jpg/272px-Leonardo_da_Vinci%2C_Salvator_Mundi%2C_c.1500%2C_oil_on_walnut%2C_45.4_%C3%97_65.6_cm.jpg" },
    { id: 16, label: 'Node 16', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Leonardo_self.jpg/255px-Leonardo_self.jpg" },
    { id: 17, label: 'Node 17', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/Kitty_Joyner_-_Electrical_Engineer_-_GPN-2000-001933.jpg/320px-Kitty_Joyner_-_Electrical_Engineer_-_GPN-2000-001933.jpg" },
    { id: 18, label: 'Node 18', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/e/e4/Johannes_Vermeer_-_The_Astronomer_-_1668.jpg/352px-Johannes_Vermeer_-_The_Astronomer_-_1668.jpg" },
    { id: 19, label: 'Node 19', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/%22The_School_of_Athens%22_by_Raffaello_Sanzio_da_Urbino.jpg/400px-%22The_School_of_Athens%22_by_Raffaello_Sanzio_da_Urbino.jpg" },
    { id: 20, label: 'Node 20', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/d/d7/Leonhard_Euler.jpg/309px-Leonhard_Euler.jpg" },
    { id: 21, label: 'Node 21', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/KnutSteen.1.jpg/309px-KnutSteen.1.jpg" },
    { id: 22, label: 'Node 22', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Leonardo_da_Vinci.jpeg/330px-Leonardo_da_Vinci.jpeg" },
    { id: 23, label: 'Node 23', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Architect.png/324px-Architect.png" },
    { id: 24, label: 'Node 24', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/f/f6/Civil_Engineering_Students.jpg/400px-Civil_Engineering_Students.jpg" },
    { id: 25, label: 'Node 25', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/f/fc/Hooghe-diplomats_coming_and_going.jpg/168px-Hooghe-diplomats_coming_and_going.jpg" },
    { id: 26, label: 'Node 26', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/1/1e/Wolfgang-amadeus-mozart_1.jpg/312px-Wolfgang-amadeus-mozart_1.jpg" },
    { id: 27, label: 'Node 27', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/Liji2_no_bg.png/400px-Liji2_no_bg.png" },
    { id: 28, label: 'Node 28', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Solvay_conference_1927.jpg/400px-Solvay_conference_1927.jpg" },
    { id: 29, label: 'Node 29', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/a/a2/Service_botanist_Mara_Alexander_taking_a_water_sample_%289666514088%29.jpg/400px-Service_botanist_Mara_Alexander_taking_a_water_sample_%289666514088%29.jpg" },
    { id: 30, label: 'Node 30', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/d/dd/Julie_Perkins_at_LLNL.jpg" },
    { id: 31, label: 'Node 31', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/6/67/Caricaturista1.jpg/400px-Caricaturista1.jpg" },
    { id: 32, label: 'Node 32', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/f/f1/InvestigadoresUR.JPG/400px-InvestigadoresUR.jpg" },
    { id: 33, label: 'Node 33', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/7/7f/Eames_chair-IMG_4624.jpg/400px-Eames_chair-IMG_4624.jpg" },
    { id: 34, label: 'Node 34', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/e/e9/Francisco_de_Goya_y_Lucientes_-_Gaspar_Melchor_de_Jovellanos.jpg/254px-Francisco_de_Goya_y_Lucientes_-_Gaspar_Melchor_de_Jovellanos.jpg" },
    { id: 35, label: 'Node 35', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/0/08/Leonardo_da_Vinci_%281452-1519%29_-_The_Last_Supper_%281495-1498%29.jpg/400px-Leonardo_da_Vinci_%281452-1519%29_-_The_Last_Supper_%281495-1498%29.jpg" },
    { id: 36, label: 'Node 36', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/e/e3/Uffizi_Gallery%2C_Florence.jpg/400px-Uffizi_Gallery%2C_Florence.jpg" },
    { id: 37, label: 'Node 37', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/e/e4/Leonardo_Da_Vinci_-_Vergine_delle_Rocce_%28Louvre%29.jpg/252px-Leonardo_Da_Vinci_-_Vergine_delle_Rocce_%28Louvre%29.jpg" },
    { id: 38, label: 'Node 38', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/Elizabeth_Vig%C3%A9e-Lebrun.jpg/288px-Elizabeth_Vig%C3%A9e-Lebrun.jpg" },
    { id: 39, label: 'Node 39', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/9/9f/Two_children%2C_Musee_du_Louvre%2C_Paris_2014.jpg/400px-Two_children%2C_Musee_du_Louvre%2C_Paris_2014.jpg" },
    { id: 40, label: 'Node 40', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Santa_Maria_delle_Grazie_Milan_2013.jpg/400px-Santa_Maria_delle_Grazie_Milan_2013.jpg" },
    { id: 41, label: 'Node 41', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/b/b3/Czartoryski_Palace%2C_17-19_%C5%9Bwi%C4%99tego_Jana_street%2C_Old_Town%2C_Krak%C3%B3w%2C_Poland.jpg/400px-Czartoryski_Palace%2C_17-19_%C5%9Bwi%C4%99tego_Jana_street%2C_Old_Town%2C_Krak%C3%B3w%2C_Poland.jpg" },
    { id: 42, label: 'Node 42', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/1/1c/Accademia_%28Venice%29.jpg/400px-Accademia_%28Venice%29.jpg" },
    { id: 43, label: 'Node 43', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Lightmatter_vaticanmuseum.jpg/400px-Lightmatter_vaticanmuseum.jpg" },
    { id: 44, label: 'Node 44', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Scalone_Pilotta.JPG/400px-Scalone_Pilotta.jpg" },
    { id: 45, label: 'Node 45', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/9/9d/Botticelli_-_Adoration_of_the_Magi_%28Zanobi_Altar%29_-_Uffizi.jpg/400px-Botticelli_-_Adoration_of_the_Magi_%28Zanobi_Altar%29_-_Uffizi.jpg" },
    { id: 46, label: 'Node 46', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/b/b2/Vincent_van_Gogh_-_Self-Portrait_-_Google_Art_Project.jpg/329px-Vincent_van_Gogh_-_Self-Portrait_-_Google_Art_Project.jpg" },
    { id: 47, label: 'Node 47', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/a/af/Vincent_Van_Gogh_-_The_Potato_Eaters.png/400px-Vincent_Van_Gogh_-_The_Potato_Eaters.png" },
    { id: 48, label: 'Node 48', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Vincent_Willem_van_Gogh_-_Cafe_Terrace_at_Night_%28Yorck%29.jpg/319px-Vincent_Willem_van_Gogh_-_Cafe_Terrace_at_Night_%28Yorck%29.jpg" },
    { id: 49, label: 'Node 49', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/e/ea/Van_Gogh_-_Starry_Night_-_Google_Art_Project.jpg/400px-Van_Gogh_-_Starry_Night_-_Google_Art_Project.jpg" },
    { id: 50, label: 'Node 50', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/7/76/Vincent_van_Gogh_-_De_slaapkamer_-_Google_Art_Project.jpg/400px-Vincent_van_Gogh_-_De_slaapkamer_-_Google_Art_Project.jpg" },
    { id: 51, label: 'Node 51', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Vincent_Willem_van_Gogh_127.jpg/317px-Vincent_Willem_van_Gogh_127.jpg" },
    { id: 52, label: 'Node 52', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/f/f0/VanGogh-self-portrait-with_bandaged_ear.jpg/326px-VanGogh-self-portrait-with_bandaged_ear.jpg" },
    { id: 53, label: 'Node 53', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/2/28/Vincent_van_Gogh_-_Landscape_with_Carriage_and_Train_in_the_Background_%281890%29.jpg/400px-Vincent_van_Gogh_-_Landscape_with_Carriage_and_Train_in_the_Background_%281890%29.jpg" },
    { id: 54, label: 'Node 54', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/3/34/Van_Gogh_Museum.jpg/400px-Van_Gogh_Museum.jpg" },
    { id: 55, label: 'Node 55', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/2/2e/Charlier_Museum%2C_entrance.jpg/254px-Charlier_Museum%2C_entrance.jpg" },
    { id: 56, label: 'Node 56', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/Stroll_About_InSpring.jpg/400px-Stroll_About_InSpring.jpg" },
    { id: 57, label: 'Node 57', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/b/bd/Jan_Bruegel_%28I%29_-_Bouquet_of_Flowers_in_a_Ceramic_Vase.jpg/200px-Jan_Bruegel_%28I%29_-_Bouquet_of_Flowers_in_a_Ceramic_Vase.jpg" },
    { id: 58, label: 'Node 58', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/c/c4/Coultauld_Galleries.jpg/400px-Coultauld_Galleries.jpg" },
    { id: 59, label: 'Node 59', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/f/f6/Gmii.jpg/400px-Gmii.jpg" },
    { id: 60, label: 'Node 60', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/b/bd/Rembrandt_van_Rijn_-_Self-Portrait_-_Google_Art_Project.jpg/309px-Rembrandt_van_Rijn_-_Self-Portrait_-_Google_Art_Project.jpg" },
    { id: 61, label: 'Node 61', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/3/3a/La_ronda_de_noche%2C_por_Rembrandt_van_Rijn.jpg/400px-La_ronda_de_noche%2C_por_Rembrandt_van_Rijn.jpg" },
    { id: 62, label: 'Node 62', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Rembrandt_-_De_Staalmeesters-_het_college_van_staalmeesters_%28waardijns%29_van_het_Amsterdamse_lakenbereidersgilde_-_Google_Art_Project.jpg/400px-Rembrandt_-_De_Staalmeesters-_het_college_van_staalmeesters_%28waardijns%29_van_het_Amsterdamse_lakenbereidersgilde_-_Google_Art_Project.jpg" },
    { id: 63, label: 'Node 63', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/0/0c/Rembrandt_Harmensz._van_Rijn_-_Portret_van_een_paar_als_oudtestamentische_figuren%2C_genaamd_%27Het_Joodse_bruidje%27_-_Google_Art_Project.jpg/400px-Rembrandt_Harmensz._van_Rijn_-_Portret_van_een_paar_als_oudtestamentische_figuren%2C_genaamd_%27Het_Joodse_bruidje%27_-_Google_Art_Project.jpg" },
    { id: 64, label: 'Node 64', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/8/8a/Rembrandt_van_Rijn%2C_The_spectacle-pedlar%2C_circa_1624%E2%80%931625%2C_Museum_De_Lakenhal%2C_Leiden.jpg/200px-Rembrandt_van_Rijn%2C_The_spectacle-pedlar%2C_circa_1624%E2%80%931625%2C_Museum_De_Lakenhal%2C_Leiden.jpg" },
    { id: 65, label: 'Node 65', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Logo_design_branding.jpg/400px-Logo_design_branding.jpg" },
    { id: 66, label: 'Node 66', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/c/c2/Largest_collection_of_Converse_All-Stars.JPG/200px-Largest_collection_of_Converse_All-Stars.jpg" },
    { id: 67, label: 'Node 67', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/1/1c/Helst%2C_Peace_of_M%C3%BCnster.jpg/200px-Helst%2C_Peace_of_M%C3%BCnster.jpg" },
    { id: 68, label: 'Node 68', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/5/5d/Rijksmuseum_2022.jpg/400px-Rijksmuseum_2022.jpg" },
    { id: 69, label: 'Node 69', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/Amsterdam_Binnenplaats_meisjes.jpg/400px-Amsterdam_Binnenplaats_meisjes.jpg" },
    { id: 70, label: 'Node 70', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/c/c2/The_idle_servant.jpg/303px-The_idle_servant.jpg" },
    { id: 71, label: 'Node 71', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/d/de/Galer%C3%ADa_Nacional%2C_Londres%2C_Inglaterra%2C_2014-08-07%2C_DD_035.JPG/400px-Galer%C3%ADa_Nacional%2C_Londres%2C_Inglaterra%2C_2014-08-07%2C_DD_035.jpg" },
    { id: 72, label: 'Node 72', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/d/d4/De_Lakenhal_Leiden.jpg/400px-De_Lakenhal_Leiden.jpg" },
    { id: 73, label: 'Node 73', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/Salvador_Dal%C3%AD_1939.jpg/311px-Salvador_Dal%C3%AD_1939.jpg" },
    { id: 74, label: 'Node 74', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/John_of_the_Cross_crucifixion_sketch.jpg/335px-John_of_the_Cross_crucifixion_sketch.jpg" },
    { id: 75, label: 'Node 75', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/e/ea/Chaplin_The_Kid_edit.jpg/296px-Chaplin_The_Kid_edit.jpg" },
    { id: 76, label: 'Node 76', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/b/bc/Steven_Spielberg_with_Chandran_Rutnam_in_Sri_Lanka.jpg" },
    { id: 77, label: 'Node 77', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/c/c4/Photographers_by_Augustas_Didzgalvis.jpg/400px-Photographers_by_Augustas_Didzgalvis.jpg" },
    { id: 78, label: 'Node 78', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Jacopo_de%27_Barbari_001.jpg/328px-Jacopo_de%27_Barbari_001.jpg" },
    { id: 79, label: 'Node 79', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/7/70/Side_View_of_the_Kelvingrove_Art_Museum.JPG/400px-Side_View_of_the_Kelvingrove_Art_Museum.jpg" },
    { id: 80, label: 'Node 80', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/b/b9/National_Gallery_of_Art_-_West_Building.JPG/400px-National_Gallery_of_Art_-_West_Building.jpg" },
    { id: 81, label: 'Node 81', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/MuseoReinaSofiaMadrid.JPG/400px-MuseoReinaSofiaMadrid.jpg" },
    { id: 82, label: 'Node 82', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/4/45/141208-JLT_8006.jpg/400px-141208-JLT_8006.jpg" },
    { id: 83, label: 'Node 83', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/6/68/Museumpark_02.JPG/400px-Museumpark_02.jpg" },
    { id: 84, label: 'Node 84', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/8/88/Paolo_Monti_-_Servizio_fotografico_%28Milano%2C_1953%29_-_BEIC_6356204.jpg/316px-Paolo_Monti_-_Servizio_fotografico_%28Milano%2C_1953%29_-_BEIC_6356204.jpg" },
    { id: 85, label: 'Node 85', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/zh/1/1c/Chicks-from-avignon.jpg" },
    { id: 86, label: 'Node 86', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/7/70/Picasso_Chicago.jpg/300px-Picasso_Chicago.jpg" },
    { id: 87, label: 'Node 87', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/zh/thumb/9/9c/Gar%C3%A7on_%C3%A0_la_pipe.jpg/321px-Gar%C3%A7on_%C3%A0_la_pipe.jpg" },
    { id: 88, label: 'Node 88', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/4/48/El_Tres_de_Mayo%2C_by_Francisco_de_Goya%2C_from_Prado_in_Google_Earth.jpg/400px-El_Tres_de_Mayo%2C_by_Francisco_de_Goya%2C_from_Prado_in_Google_Earth.jpg" },
    { id: 89, label: 'Node 89', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/d/de/Shamir_Brothers.jpg/400px-Shamir_Brothers.jpg" },
    { id: 90, label: 'Node 90', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/3/3d/Flickr_-_%E2%80%A6trialsanderrors_-_Le_Ballon%2C_advertising_for_French_aeronautical_journal%2C_ca._1883.jpg/258px-Flickr_-_%E2%80%A6trialsanderrors_-_Le_Ballon%2C_advertising_for_French_aeronautical_journal%2C_ca._1883.jpg" },
    { id: 91, label: 'Node 91', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/9/9e/Proteus_Fishman_Costume_1907_Wikstorm.jpg/245px-Proteus_Fishman_Costume_1907_Wikstorm.jpg" },
    { id: 92, label: 'Node 92', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/7/70/Jacques-Louis_David_-_Oath_of_the_Horatii_-_Google_Art_Project.jpg/400px-Jacques-Louis_David_-_Oath_of_the_Horatii_-_Google_Art_Project.jpg" },
    { id: 93, label: 'Node 93', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/8/8e/African-Songye-Power-Figure-and-Hopi-Ahola-Katsina-Doll.jpg/200px-African-Songye-Power-Figure-and-Hopi-Ahola-Katsina-Doll.jpg" },
    { id: 94, label: 'Node 94', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/c/cd/Iron_Man_-_Antony_Gormley_Statue_-_Victoria_Square_-_Birmingham_-_2005-10-14.jpg/300px-Iron_Man_-_Antony_Gormley_Statue_-_Victoria_Square_-_Birmingham_-_2005-10-14.jpg" },
    { id: 95, label: 'Node 95', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/4/48/Museu_Picasso_Barcelona.jpg/295px-Museu_Picasso_Barcelona.jpg" },
    { id: 96, label: 'Node 96', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/H%C3%B4tel_Sal%C3%A9.JPG/400px-H%C3%B4tel_Sal%C3%A9.jpg" },
    { id: 97, label: 'Node 97', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Claude_Monet_1899_Nadar_crop.jpg/300px-Claude_Monet_1899_Nadar_crop.jpg" },
    { id: 98, label: 'Node 98', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/5/54/Claude_Monet%2C_Impression%2C_soleil_levant.jpg/400px-Claude_Monet%2C_Impression%2C_soleil_levant.jpg" },
    { id: 99, label: 'Node 99', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/a/a0/Claude_Monet_-_Jardin_%C3%A0_Sainte-Adresse.jpg/400px-Claude_Monet_-_Jardin_%C3%A0_Sainte-Adresse.jpg" },
    { id: 100, label: 'Node 100', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/5/5d/Claude_Monet_-_La_Corniche_near_Monaco_-_Google_Art_Project.jpg/200px-Claude_Monet_-_La_Corniche_near_Monaco_-_Google_Art_Project.jpg" },
    { id: 101, label: 'Node 101', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/3/30/RouenCathedral_Monet_1894.jpg/256px-RouenCathedral_Monet_1894.jpg" },
    { id: 102, label: 'Node 102', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/5/5d/De_Vlieger%2C_Brederode_off_Hellevoetsluis.jpg/400px-De_Vlieger%2C_Brederode_off_Hellevoetsluis.jpg" },
    { id: 103, label: 'Node 103', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/b/b7/Mus%C3%A9e_Marmottan.JPG/400px-Mus%C3%A9e_Marmottan.jpg" },
    { id: 104, label: 'Node 104', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/7/73/The_Metropolitan_Museum_of_Art_Logo.svg/400px-The_Metropolitan_Museum_of_Art_Logo.svg.png" },
    { id: 105, label: 'Node 105', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/f/f6/Raffaello_Sanzio.jpg/293px-Raffaello_Sanzio.jpg" },
    { id: 106, label: 'Node 106', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/d/d4/La_Fornarina_by_Raffaello.jpg/312px-La_Fornarina_by_Raffaello.jpg" },
    { id: 107, label: 'Node 107', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/%22The_School_of_Athens%22_by_Raffaello_Sanzio_da_Urbino.jpg/400px-%22The_School_of_Athens%22_by_Raffaello_Sanzio_da_Urbino.jpg" },
    { id: 108, label: 'Node 108', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/0_Adam_et_Eve_-_Fresque_de_Rapha%C3%ABl_-_Stanza_della_Signatura_%282%29.JPG/329px-0_Adam_et_Eve_-_Fresque_de_Rapha%C3%ABl_-_Stanza_della_Signatura_%282%29.jpg" },
    { id: 109, label: 'Node 109', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/e/ef/Rafael_-_ressureicaocristo01.jpg/328px-Rafael_-_ressureicaocristo01.jpg" },
    { id: 110, label: 'Node 110', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/3/39/Raffaello_Sanzio_-_Madonna_and_Child_-_WGA18620.jpg/291px-Raffaello_Sanzio_-_Madonna_and_Child_-_WGA18620.jpg" },
    { id: 111, label: 'Node 111', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/0/06/Raffaello_-_Spozalizio_-_Web_Gallery_of_Art.jpg/279px-Raffaello_-_Spozalizio_-_Web_Gallery_of_Art.jpg" },
    { id: 112, label: 'Node 112', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/4/48/Madona_del_gran_duque%2C_por_Rafael.jpg/272px-Madona_del_gran_duque%2C_por_Rafael.jpg" },
    { id: 113, label: 'Node 113', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/9/9c/Raffael_031.jpg/279px-Raffael_031.jpg" },
    { id: 114, label: 'Node 114', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/7/7a/Belvedere_madonna.jpg/314px-Belvedere_madonna.jpg" },
    { id: 115, label: 'Node 115', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/f/f2/Raffaello%2C_pala_baglioni%2C_deposizione.jpg/387px-Raffaello%2C_pala_baglioni%2C_deposizione.jpg" },
    { id: 116, label: 'Node 116', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/7/7a/RAFAEL_-_Madonna_Sixtina_%28Gem%C3%A4ldegalerie_Alter_Meister%2C_Dresden%2C_1513-14._%C3%93leo_sobre_lienzo%2C_265_x_196_cm%29.jpg/292px-RAFAEL_-_Madonna_Sixtina_%28Gem%C3%A4ldegalerie_Alter_Meister%2C_Dresden%2C_1513-14._%C3%93leo_sobre_lienzo%2C_265_x_196_cm%29.jpg" },
    { id: 117, label: 'Node 117', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/Transfigurazione_%28Raffaello%29_September_2015-1a.jpg/274px-Transfigurazione_%28Raffaello%29_September_2015-1a.jpg" },
    { id: 118, label: 'Node 118', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/a/a1/William-Adolphe_Bouguereau_%281825-1905%29_-_Biblis_%281884%29.jpg/400px-William-Adolphe_Bouguereau_%281825-1905%29_-_Biblis_%281884%29.jpg" },
    { id: 119, label: 'Node 119', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/9/9d/MASP_Brazil.jpg/400px-MASP_Brazil.jpg" },
    { id: 120, label: 'Node 120', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/6/60/Norton_Simon_Museum_Pasadena_CA_%2851081778692%29.jpg/400px-Norton_Simon_Museum_Pasadena_CA_%2851081778692%29.jpg" },
    { id: 121, label: 'Node 121', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/d/db/Milano_brera_cortile.jpg/400px-Milano_brera_cortile.jpg" },
    { id: 122, label: 'Node 122', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/4/40/Florence-PalaisPitti.jpg/400px-Florence-PalaisPitti.jpg" },
    { id: 123, label: 'Node 123', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/IMG_0089_-_Wien_-_Kunsthistorisches_Museum.JPG/400px-IMG_0089_-_Wien_-_Kunsthistorisches_Museum.jpg" },
    { id: 124, label: 'Node 124', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/5/5d/Galleria_borghese_facade.jpg/400px-Galleria_borghese_facade.jpg" },
    { id: 125, label: 'Node 125', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/2/23/Jackson_Pollock.jpg/321px-Jackson_Pollock.jpg" },
    { id: 126, label: 'Node 126', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/4/42/National_Gallery_of_Australia_June_2012.JPG/400px-National_Gallery_of_Australia_June_2012.jpg" },
    { id: 127, label: 'Node 127', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/c/ca/Self-portrait_by_Peter_Paul_Rubens.jpg/293px-Self-portrait_by_Peter_Paul_Rubens.jpg" },
    { id: 128, label: 'Node 128', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/0/06/Peter_Paul_Rubens_063.jpg/311px-Peter_Paul_Rubens_063.jpg" },
    { id: 129, label: 'Node 129', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/9/9e/Peter_Paul_Rubens_110.jpg/400px-Peter_Paul_Rubens_110.jpg" },
    { id: 130, label: 'Node 130', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/f/ff/Peter_Paul_Rubens_Peter_Paul_Rubens_-_The_Artist_and_His_First_Wife%2C_Isabella_Brant%2C_in_the_Honeysuckle_Bower.jpg/306px-Peter_Paul_Rubens_Peter_Paul_Rubens_-_The_Artist_and_His_First_Wife%2C_Isabella_Brant%2C_in_the_Honeysuckle_Bower.jpg" },
    { id: 131, label: 'Node 131', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/9/9c/Rubens%2C_Peter_Paul_-_The_Three_Graces.jpg/328px-Rubens%2C_Peter_Paul_-_The_Three_Graces.jpg" },
    { id: 132, label: 'Node 132', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/c/c7/El_jard%C3%ADn_del_amor_Rubens_lou.jpg/400px-El_jard%C3%ADn_del_amor_Rubens_lou.jpg" },
    { id: 133, label: 'Node 133', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/5/5d/Peter_Paul_Rubens_-_Rape_of_the_Daughters_of_Leucippus_-_WGA20299.jpg/375px-Peter_Paul_Rubens_-_Rape_of_the_Daughters_of_Leucippus_-_WGA20299.jpg" },
    { id: 134, label: 'Node 134', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/Le_Chapeau_de_Paille_by_Peter_Paul_Rubens.jpg/273px-Le_Chapeau_de_Paille_by_Peter_Paul_Rubens.jpg" },
    { id: 135, label: 'Node 135', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/5/52/Peter_Paul_Rubens_-_De_kruisoprichting.JPG/297px-Peter_Paul_Rubens_-_De_kruisoprichting.jpg" },
    { id: 136, label: 'Node 136', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/M%C3%BCnchen_-_Pinakotheken_%28Luftbild%29.jpg/400px-M%C3%BCnchen_-_Pinakotheken_%28Luftbild%29.jpg" },
    { id: 137, label: 'Node 137', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/f/f6/Andreas_Achenbach_-_The_Academy_Courtyard_%28The_Old_Academy_in_D%C3%BCsseldorf%29_-_Google_Art_Project.jpg/400px-Andreas_Achenbach_-_The_Academy_Courtyard_%28The_Old_Academy_in_D%C3%BCsseldorf%29_-_Google_Art_Project.jpg" },
    { id: 138, label: 'Node 138', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/6/68/Museo_del_Prado_2016_%2825185969599%29.jpg/400px-Museo_del_Prado_2016_%2825185969599%29.jpg" },
    { id: 139, label: 'Node 139', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/2/26/Exterior_of_Onze-Lieve-Vrouwekathedraal_%28Antwerp%29_20180629-1.jpg/266px-Exterior_of_Onze-Lieve-Vrouwekathedraal_%28Antwerp%29_20180629-1.jpg" },
    { id: 140, label: 'Node 140', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/d/d4/Sandro_Botticelli_083.jpg/366px-Sandro_Botticelli_083.jpg" },
    { id: 141, label: 'Node 141', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/0/0b/Sandro_Botticelli_-_La_nascita_di_Venere_-_Google_Art_Project_-_edited.jpg/400px-Sandro_Botticelli_-_La_nascita_di_Venere_-_Google_Art_Project_-_edited.jpg" },
    { id: 142, label: 'Node 142', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/9/9d/Botticelli_-_Adoration_of_the_Magi_%28Zanobi_Altar%29_-_Uffizi.jpg/400px-Botticelli_-_Adoration_of_the_Magi_%28Zanobi_Altar%29_-_Uffizi.jpg" },
    { id: 143, label: 'Node 143', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Botticelli-primavera.jpg/400px-Botticelli-primavera.jpg" },
    { id: 144, label: 'Node 144', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/it/thumb/d/d3/Botticelli%2C_fortezza_480.jpg/212px-Botticelli%2C_fortezza_480.jpg" },
    { id: 145, label: 'Node 145', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/Eug%C3%A8ne_Delacroix_-_La_libert%C3%A9_guidant_le_peuple.jpg/400px-Eug%C3%A8ne_Delacroix_-_La_libert%C3%A9_guidant_le_peuple.jpg" },
    { id: 146, label: 'Node 146', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/d/d6/Wassily_kandinsky.jpg/276px-Wassily_kandinsky.jpg" },
    { id: 147, label: 'Node 147', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/b/b4/Vassily_Kandinsky%2C_1913_-_Composition_7.jpg/400px-Vassily_Kandinsky%2C_1913_-_Composition_7.jpg" },
    { id: 148, label: 'Node 148', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/8/8e/Quentin_Massys_-_Portrait_of_a_Man_-_National_Gallery_of_Scotland.jpg/324px-Quentin_Massys_-_Portrait_of_a_Man_-_National_Gallery_of_Scotland.jpg" },
    { id: 149, label: 'Node 149', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/0/06/The_State_Tretyakov_Gallery.jpg/400px-The_State_Tretyakov_Gallery.jpg" },
    { id: 150, label: 'Node 150', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Cropped_version_of_Jan_Vermeer_van_Delft_002.jpg/287px-Cropped_version_of_Jan_Vermeer_van_Delft_002.jpg" },
    { id: 151, label: 'Node 151', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/Vermeer_-_Diana_and_Her_Companions.jpg/400px-Vermeer_-_Diana_and_Her_Companions.jpg" },
    { id: 152, label: 'Node 152', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/2/2a/Johannes_Vermeer_-_The_Geographer_-_Google_Art_Project.jpg/357px-Johannes_Vermeer_-_The_Geographer_-_Google_Art_Project.jpg" },
    { id: 153, label: 'Node 153', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Jan_Vermeer_van_Delft_002.jpg/359px-Jan_Vermeer_van_Delft_002.jpg" },
    { id: 154, label: 'Node 154', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/2/20/Johannes_Vermeer_-_Het_melkmeisje_-_Google_Art_Project.jpg/357px-Johannes_Vermeer_-_Het_melkmeisje_-_Google_Art_Project.jpg" },
    { id: 155, label: 'Node 155', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/b/bf/Jan_Vermeer_%28attr.%29_-_Christ_in_the_House_of_Martha_and_Mary_-_National_Gallery_of_Scotland.jpg/354px-Jan_Vermeer_%28attr.%29_-_Christ_in_the_House_of_Martha_and_Mary_-_National_Gallery_of_Scotland.jpg" },
    { id: 156, label: 'Node 156', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/e/e4/Johannes_Vermeer_-_The_Astronomer_-_1668.jpg/352px-Johannes_Vermeer_-_The_Astronomer_-_1668.jpg" },
    { id: 157, label: 'Node 157', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Vermeer_young_women_sleeping.jpg/347px-Vermeer_young_women_sleeping.jpg" },
    { id: 158, label: 'Node 158', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/d/d7/Meisje_met_de_parel.jpg/342px-Meisje_met_de_parel.jpg" },
    { id: 159, label: 'Node 159', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/3/37/GraphyArchy_-_Wikipedia_00316.jpg/400px-GraphyArchy_-_Wikipedia_00316.jpg" },
    { id: 160, label: 'Node 160', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/FrankfurtM_Staedel.jpg/400px-FrankfurtM_Staedel.jpg" },
    { id: 161, label: 'Node 161', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/Demidov_collection_-_Palais_de_San-Donato_Catalogue.jpg/282px-Demidov_collection_-_Palais_de_San-Donato_Catalogue.jpg" },
    { id: 162, label: 'Node 162', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/4/48/National_Gallery_of_Scotland_restitch1_2005-08-07.jpg/400px-National_Gallery_of_Scotland_restitch1_2005-08-07.jpg" },
    { id: 163, label: 'Node 163', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/5/52/Autorretrato_con_gafas_por_Francisco_de_Goya_%28Mus%C3%A9e_Bonnat-Helleu%29.jpg/281px-Autorretrato_con_gafas_por_Francisco_de_Goya_%28Mus%C3%A9e_Bonnat-Helleu%29.jpg" },
    { id: 164, label: 'Node 164', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/Goya_Maja_naga2.jpg/400px-Goya_Maja_naga2.jpg" },
    { id: 165, label: 'Node 165', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/8/8e/San_Bernardino_de_Siena_%28Goya%29.jpg/207px-San_Bernardino_de_Siena_%28Goya%29.jpg" },
    { id: 166, label: 'Node 166', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/3/38/Goya_Dog.jpg/235px-Goya_Dog.jpg" },
    { id: 167, label: 'Node 167', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/e/eb/Francisco_de_Goya_y_Lucientes_-_Portrait_of_Ram%C3%B3n_Satu%C3%A9_-_WGA10096.jpg/319px-Francisco_de_Goya_y_Lucientes_-_Portrait_of_Ram%C3%B3n_Satu%C3%A9_-_WGA10096.jpg" },
    { id: 168, label: 'Node 168', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/4/48/El_Tres_de_Mayo%2C_by_Francisco_de_Goya%2C_from_Prado_in_Google_Earth.jpg/400px-El_Tres_de_Mayo%2C_by_Francisco_de_Goya%2C_from_Prado_in_Google_Earth.jpg" },
    { id: 169, label: 'Node 169', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/a/a6/Goya_Maja_ubrana2.jpg/400px-Goya_Maja_ubrana2.jpg" },
    { id: 170, label: 'Node 170', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/e/e3/Le_songe_de_la_raison.jpg/269px-Le_songe_de_la_raison.jpg" },
    { id: 171, label: 'Node 171', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/9/9e/La_%C3%BAltima_comuni%C3%B3n_de_san_Jos%C3%A9_de_Calasanz.jpg/285px-La_%C3%BAltima_comuni%C3%B3n_de_san_Jos%C3%A9_de_Calasanz.jpg" },
    { id: 172, label: 'Node 172', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/d/da/Carga_de_los_mamelucos_restaurado.jpg/400px-Carga_de_los_mamelucos_restaurado.jpg" },
    { id: 173, label: 'Node 173', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/9/9d/Jos%C3%A9_de_Palafox_por_Goya_%28Prado%29.jpg/200px-Jos%C3%A9_de_Palafox_por_Goya_%28Prado%29.jpg" },
    { id: 174, label: 'Node 174', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/9/9b/Wappen_der_Lithographen_Steindrucker_und_Offsetdrucker.jpg/276px-Wappen_der_Lithographen_Steindrucker_und_Offsetdrucker.jpg" },
    { id: 175, label: 'Node 175', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/f/f7/Louis_George_Meistergraveurin_2.jpg/400px-Louis_George_Meistergraveurin_2.jpg" },
    { id: 176, label: 'Node 176', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/2/26/Bas%C3%ADlica_de_San_Francisco_el_Grande_%28Madrid%29_13.jpg/400px-Bas%C3%ADlica_de_San_Francisco_el_Grande_%28Madrid%29_13.jpg" },
    { id: 177, label: 'Node 177', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/c/cb/TaylorPlate.jpg/250px-TaylorPlate.jpg" },
    { id: 178, label: 'Node 178', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/8/8c/Palacio_de_Goyeneche_-_Real_Academia_de_Bellas_Artes_de_San_Fernando.jpg/400px-Palacio_de_Goyeneche_-_Real_Academia_de_Bellas_Artes_de_San_Fernando.jpg" },
    { id: 179, label: 'Node 179', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/6/60/Representaciones_ecuestres_del_soberano.png/339px-Representaciones_ecuestres_del_soberano.png" },
    { id: 180, label: 'Node 180', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/Miguel_%C3%81ngel%2C_por_Daniele_da_Volterra_%28detalle%29.jpg/345px-Miguel_%C3%81ngel%2C_por_Daniele_da_Volterra_%28detalle%29.jpg" },
    { id: 181, label: 'Node 181', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/1/1f/Michelangelo%27s_Pieta_5450_cropncleaned_edit.jpg/382px-Michelangelo%27s_Pieta_5450_cropncleaned_edit.jpg" },
    { id: 182, label: 'Node 182', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/1/18/Last_Judgement_%28Michelangelo%29.jpg/363px-Last_Judgement_%28Michelangelo%29.jpg" },
    { id: 183, label: 'Node 183', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/Tondo_Doni%2C_por_Miguel_%C3%81ngel.jpg/393px-Tondo_Doni%2C_por_Miguel_%C3%81ngel.jpg" },
    { id: 184, label: 'Node 184', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/7/7b/Michelangelo%2C_madonna_della_scala%2C_1491_ca%2C_01.JPG/287px-Michelangelo%2C_madonna_della_scala%2C_1491_ca%2C_01.jpg" },
    { id: 185, label: 'Node 185', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/3/39/Michelangelo%2C_centauromachia%2C_1492_ca._01.JPG/400px-Michelangelo%2C_centauromachia%2C_1492_ca._01.jpg" },
    { id: 186, label: 'Node 186', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/a/aa/Firenze_-_Museo_Opera_del_Duomo%2C_Piet%C3%A0_Bandini.jpg/266px-Firenze_-_Museo_Opera_del_Duomo%2C_Piet%C3%A0_Bandini.jpg" },
    { id: 187, label: 'Node 187', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/a/a6/Michelangelo%2C_piet%C3%A0_rondanini%2C_1552-1564%2C_01.JPG/266px-Michelangelo%2C_piet%C3%A0_rondanini%2C_1552-1564%2C_01.jpg" },
    { id: 188, label: 'Node 188', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/it/thumb/2/21/Michelangelo%2C_san_matteo.jpg/163px-Michelangelo%2C_san_matteo.jpg" },
    { id: 189, label: 'Node 189', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Medicibib.jpg/400px-Medicibib.jpg" },
    { id: 190, label: 'Node 190', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/it/thumb/3/3b/Michelangelo%2C_bacco_01.jpg/170px-Michelangelo%2C_bacco_01.jpg" },
    { id: 191, label: 'Node 191', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/Michelangelo_Brutus.jpg/286px-Michelangelo_Brutus.jpg" },
    { id: 192, label: 'Node 192', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/it/thumb/e/e0/Michelangelo%2C_schiavo_che_si_ridesta.jpg/205px-Michelangelo%2C_schiavo_che_si_ridesta.jpg" },
    { id: 193, label: 'Node 193', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/US_Navy_030402-N-0000X-013_Breaking_ground_for_the_new_Port_Operations_Tower%2C_from_left%2C_Paul_Littlefield%2C_project_manager_of_S.B._Ballard.jpg/400px-US_Navy_030402-N-0000X-013_Breaking_ground_for_the_new_Port_Operations_Tower%2C_from_left%2C_Paul_Littlefield%2C_project_manager_of_S.B._Ballard.jpg" },
    { id: 194, label: 'Node 194', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/0/0b/Casa_buonarroti_11.JPG/300px-Casa_buonarroti_11.jpg" },
    { id: 195, label: 'Node 195', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/Nuovo_museo_dell%27opera_del_duomo%2C_facciatone_arnolfiano_di_santa_maria_del_fiore%2C_000.jpg/400px-Nuovo_museo_dell%27opera_del_duomo%2C_facciatone_arnolfiano_di_santa_maria_del_fiore%2C_000.jpg" },
    { id: 196, label: 'Node 196', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/David_von_Michelangelo.jpg/209px-David_von_Michelangelo.jpg" },
    { id: 197, label: 'Node 197', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/c/c6/Il_Bargello.jpg/300px-Il_Bargello.jpg" },
    { id: 198, label: 'Node 198', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/1/19/Louvre_Grab.JPG/400px-Louvre_Grab.jpg" },
    { id: 199, label: 'Node 199', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/4/42/Andy_Warhol_1975.jpg/295px-Andy_Warhol_1975.jpg" },
    { id: 200, label: 'Node 200', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/7/7d/US_Navy_040615-N-6817C-030_A_camera_crew_sets_up_for_scenes_to_be_taped_on_the_flight_deck_for_the_upcoming_motion_picture_Stealth.jpg/400px-US_Navy_040615-N-6817C-030_A_camera_crew_sets_up_for_scenes_to_be_taped_on_the_flight_deck_for_the_upcoming_motion_picture_Stealth.jpg" },
    { id: 201, label: 'Node 201', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/9/9d/GeorgianaofDevonshire.jpg/320px-GeorgianaofDevonshire.jpg" },
    { id: 202, label: 'Node 202', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/c/cb/Angelina_Probst_the_sexiest_gallery_Berlin_Germany_3.jpg/400px-Angelina_Probst_the_sexiest_gallery_Berlin_Germany_3.jpg" },
    { id: 203, label: 'Node 203', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/Graffiti_%28521190779%29.jpg/266px-Graffiti_%28521190779%29.jpg" },
    { id: 204, label: 'Node 204', shape: "circularImage", "image":"https://upload.wikimedia.org/wikipedia/commons/thumb/f/f5/Female_Singers_Unit_Have_Sense_in_New_Zuoying_Station_Hall_20140811.jpg/400px-Female_Singers_Unit_Have_Sense_in_New_Zuoying_Station_Hall_20140811.jpg" }
 ]

const App = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true) 
  const [nodes, setNodes] = useState([NODES_DATA[0]])
  const [edges, setEdges]   = useState([])
  const [index, setIndex] = useState(1) 

  const loginValidate = async () => {
    let URL = "https://auth-server-tkbp.onrender.com/login/sdtech/validate"
    let response = await axios.post(URL, JSON.stringify({
        't': sessionStorage.getItem('token')
    }),{ headers:{ "Content-Type": "application/json; charset=UTF-8"}})
    console.log(response)
    if(response && response.data && response.data.status === "RES_SUCCESS"){
      setLoading(false)
    }
    else{
      sessionStorage.removeItem('token')
      navigate("/login");
    }
  }

  useEffect(() => {
    loginValidate()
  }, []);

  const getRandomInt = (max) => {
    return Math.floor(Math.random() * max);
  }

  const checkEdgeExist = (_edges, from, to) => {
    let n = 0;
    while(n < _edges.length){
      let _edge = _edges[n]
      if((_edge.from === from && _edge.to === to) || 
         (_edge.from === to && _edge.to === from)){
          return true
         }
      n++
    }
    return false;
  }

  const addEdgesToNode = (edge_count, index) => {
    let _edges = []
    let n = 0;

    while(n<edge_count){
      let num = getRandomInt(nodes.length)
      if(checkEdgeExist(edges, NODES_DATA[index].id, num + 1)){
        continue;
      }
      else{
        if(checkEdgeExist(_edges, NODES_DATA[index].id, num + 1)){
          continue;
        }
        _edges.push({ from: NODES_DATA[index].id, to: num + 1 })
        n++
      }
    }

    return _edges
  }

  const onAdd1Node1Edge = () => {
    if(index >= NODES_DATA.length) return;

    setNodes([
      ...nodes,
      NODES_DATA[index]
    ])

    setEdges([
      ...edges,
      { from: NODES_DATA[index].id, to: getRandomInt(nodes.length -1 ) + 1 },
    ])
    setIndex(index+1);
  }

  const onAdd10Nodes1Edge = () => {
    if(index + 10 > NODES_DATA.length) return;
    let _nodes = []
    let _edges = []
    let _index = index;

    while (_index < index + 10) {
      _nodes.push(NODES_DATA[_index])
      _edges.push({ from: NODES_DATA[_index].id, to: getRandomInt(nodes.length -1 ) + 1 })
      _index++;
    }

    setNodes([
      ...nodes,
      ..._nodes,
    ])

    setEdges([
      ...edges,
      ..._edges,
    ])
    setIndex(_index);
  }
  
  const onAdd1Node3Edges = () => {
    if(index >= NODES_DATA.length) return;
    if(nodes.length < 10) return;

    setNodes([
      ...nodes,
      NODES_DATA[index]
    ])

    setEdges([
      ...edges,
      ...addEdgesToNode(3, index),
    ])
    setIndex(index+1);
  }
  
  const onAdd10Nodes3Edges = () => {
    if(index + 10 > NODES_DATA.length) return;
    let _nodes = []
    let _edges = []
    let _index = index;

    while (_index < index + 10) {
      _nodes.push(NODES_DATA[_index])
      _edges = [..._edges, ...addEdgesToNode(3, _index)]
      _index++;
    }

    setNodes([
      ...nodes,
      ..._nodes,
    ])

    setEdges([
      ...edges,
      ..._edges,
    ])
    setIndex(_index);
  }

  const onLogout = () => {
    sessionStorage.removeItem('token')
    navigate("/login");
  }

  if(loading){
    return (
      <div className="App">
        <div className="auth-wrapper">
          <div className="auth-inner">
            <h3>Authenticating...</h3>
          </div>
        </div>
      </div>
    )
  }
  else{
    return (
      <div className='index_body'>
        <div>
          <h1>Knowledge Graph Visualization</h1>
          <div styles={{flexDirection: 'row', alignContent: 'space-between', padding: '20px'}}>
            <button style={{marginRight: '20px'}} onClick={onAdd1Node1Edge}>{"Add 1 Node (1 Edge)"}</button>
            <button style={{marginRight: '20px'}} onClick={onAdd10Nodes1Edge}>{"Add 10 Nodes (1 Edge)"}</button>
            <button disabled={nodes.length > 10 ? false : true} style={{marginRight: '20px'}} onClick={onAdd1Node3Edges}>{"Add 1 Node (3 Edges)"}</button>
            <button disabled={nodes.length > 10 ? false : true}  style={{marginRight: '20px'}} onClick={onAdd10Nodes3Edges}>{"Add 10 Nodes (3 Edges)"}</button>
            <button  style={{marginRight: '20px', float:'right'}} onClick={onLogout}>{"Logout"}</button>
          </div>
          <br />
          <div>Nodes: {index}</div>
        </div>
        <br/>
        <div style={{ display:"flex"}}>
          <KnowledgeGraph data={{nodes: nodes, edges: edges}} />
        </div>
      </div>
    );
  }

};

export default App;
